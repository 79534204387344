import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Image from '../Image/Image';
import MoreLink from '../MoreLink/MoreLink';
import ParallaxWithBgr from '../ParallaxWithBgr/ParallaxWithBgr';
import ThinkingPageShape from '../svgs/ThinkingPageShape';
import * as styles from './ContentfulArticleSummary.module.scss';
import cx from 'classnames';
import { Content } from '@vccp/bernadette-components';
import InternalLink from '../InternalLink/InternalLink';

const ContentfulArticleSummary = () => {
  const data = useStaticQuery(articleSummaryQuery);
  const articles = data.allContentfulThinking.nodes;
  return (
    <ParallaxWithBgr>
      <Content>
        <div className={styles.articleSummaryWrapper}>
          {articles.map((item, index) => {
            return (
              <div
                key={item.id}
                className={cx(
                  styles.articleSummaryContainer,
                  index % 2 !== 0 && styles.articleSummaryContainerEven,
                )}>
                <InternalLink pageId={item.id}>
                  <div>
                    <Image
                      {...item.image}
                      className={styles.articleSummaryImage}
                    />
                  </div>
                  <span className={styles.articleSummaryDate}>{item.date}</span>
                  <h3 className={styles.articleSummaryTitle}>{item.title}</h3>
                  {/*<MoreLink pageId={item.id}>Read article</MoreLink>*/}
                  <div>
                    Read article<span></span>
                  </div>
                </InternalLink>
              </div>
            );
          })}
        </div>
      </Content>
    </ParallaxWithBgr>
  );
};

export default ContentfulArticleSummary;

export const articleSummaryQuery = graphql`
  query ArticleSummaryQuery {
    allContentfulThinking(
      sort: { fields: date, order: DESC }
      filter: {
        title: { ne: "__DUMMY_ITEM__" }
        priorityThinking: { ne: true }
      }
    ) {
      nodes {
        id
        slug
        title
        priorityThinking
        date(formatString: "DD/MM/YY")
        image {
          gatsbyImageData
          file {
            url
          }
          title
        }
      }
    }
  }
`;

import React from 'react';
import cx from 'classnames';
import * as styles from './MoreLink.module.scss';
import { ArrowIcon } from '@vccp/bernadette-components';
import {
  TextColorTextDefault,
  NeutralPaletteNeutral10,
} from '../../styles/tokens/variables';
import InternalLink from '../InternalLink/InternalLink';

const MoreLink = ({
  children,
  className,
  isDark = false,
  isDarkSecondary = false,
  ...rest
}) => (
  <InternalLink {...rest} className={cx(styles.moreLink, className)}>
    <span
      className={cx(
        styles.text,
        isDark && styles.blackText,
        isDarkSecondary && styles.formLinkStyles,
      )}>
      {children}
    </span>
    <span className={styles.icon}>
      <ArrowIcon
        color={
          isDark || isDarkSecondary
            ? NeutralPaletteNeutral10
            : TextColorTextDefault
        }
      />
    </span>
  </InternalLink>
);

export default MoreLink;

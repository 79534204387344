// extracted by mini-css-extract-plugin
export var colReverse = "ContentfulQuote-module--col-reverse--GOZ1O";
export var quoteAuthor = "ContentfulQuote-module--quote-author--wxJTt";
export var quoteImage = "ContentfulQuote-module--quote-image--N-CBb";
export var quoteImageDecoration = "ContentfulQuote-module--quote-image-decoration--UH02r";
export var quoteNoImage = "ContentfulQuote-module--quote-no-image--EyEV6";
export var quoteNoImageDesc = "ContentfulQuote-module--quote-no-image-desc--k2UVN";
export var quoteText = "ContentfulQuote-module--quote-text--gVWOs";
export var quoteTextContent = "ContentfulQuote-module--quote-text-content--tG1Sm";
export var quoteTitle = "ContentfulQuote-module--quote-title--L7cC5";
export var quoteWithImage = "ContentfulQuote-module--quote-with-image--8bx3j";
export var quoteWrapper = "ContentfulQuote-module--quote-wrapper--BI0V8";
export var quoteWrapperOuter = "ContentfulQuote-module--quote-wrapper-outer--3S+nh";
export var rowReverse = "ContentfulQuote-module--row-reverse--VjeTi";
// extracted by mini-css-extract-plugin
export var moreLinkContainer = "FeaturedThinkingArticle-module--more-link-container--3MtEN";
export var moreLinkSpan = "FeaturedThinkingArticle-module--more-link-span--gpvi1";
export var thinkingArticleBorderYellow = "FeaturedThinkingArticle-module--thinking-article-border-yellow--P3Xbo";
export var thinkingArticleCircle = "FeaturedThinkingArticle-module--thinking-article-circle--kyTO6";
export var thinkingArticleContainer = "FeaturedThinkingArticle-module--thinking-article-container--2SYb0";
export var thinkingArticleDate = "FeaturedThinkingArticle-module--thinking-article-date--IK5lH";
export var thinkingArticleImage = "FeaturedThinkingArticle-module--thinking-article-image--CVf2s";
export var thinkingArticleImageContainer = "FeaturedThinkingArticle-module--thinking-article-image-container--UAhlm";
export var thinkingArticleText = "FeaturedThinkingArticle-module--thinking-article-text--zvmx3";
export var thinkingArticleTitle = "FeaturedThinkingArticle-module--thinking-article-title--EyUaX";
export var thinkingArticleWrapper = "FeaturedThinkingArticle-module--thinking-article-wrapper--gPIEL";
/**
 * Do not edit directly
 * Generated on Tue, 11 Jun 2024 08:57:37 GMT
 */

export const PrimaryBrandPrimary = "#6feedb";
export const PrimaryBrandPrimaryDark = "#1a1a1a";
export const SecondaryPaletteSecondary1 = "#ffffff";
export const SecondaryPaletteSecondary2 = "#ff5c5c";
export const SecondaryPaletteSecondary3 = "#f3e01c";
export const SecondaryPaletteSecondary4 = "#1ce8f4";
export const NeutralPaletteNeutral10 = "#000000";
export const NeutralPaletteNeutral100 = "#FFFFFF";
export const NeutralPalette50 = "#959595";
export const BackgroundColorBackground = "#1a1a1a";
export const BackgroundColorBackgroundLight = "#ffffff";
export const BackgroundColorBackgroundPrimaryActive = "#6feedb";
export const BackgroundColorBackgroundSecondaryActive = "#ffffff";
export const BackgroundColorBackgroundCustom1 = "#ff5c5c";
export const BackgroundColorBackgroundCustom2 = "#d11278";
export const BackgroundColorBackgroundCustom3 = "#1ce8f4";
export const BackgroundColorBackgroundCustom4 = "#ebc373";
export const BackgroundColorBackgroundCustom5 = "#a888fd";
export const TextColorTextDefault = "#ffffff";
export const TextColorTextDefaultLight = "#1a1a1a";
export const TextColorTextContrast = "#ffffff";
export const TextColorTextBrand = "#6feedb";
export const TextColorTextError = "#ff5c5c";
export const FontFamilyPrimary = "Inter Tight";
export const FontFamilySecondary = "Bebas Neue";
export const DesktopFontSize10 = "92px";
export const DesktopFontSize9 = "72px";
export const DesktopFontSize8 = "68px";
export const DesktopFontSize7 = "50px";
export const DesktopFontSize6 = "42px";
export const DesktopFontSize5 = "32px";
export const DesktopFontSize4 = "24px";
export const DesktopFontSize3 = "18px";
export const DesktopFontSize2 = "16px";
export const DesktopFontSize1 = "14px";
export const DesktopLetterSpacing30 = "30";
export const MobileFontSize10 = "64px";
export const MobileFontSize9 = "50px";
export const MobileFontSize8 = "44px";
export const MobileFontSize7 = "40px";
export const MobileFontSize6 = "42px";
export const MobileFontSize5 = "32px";
export const MobileFontSize4 = "24px";
export const MobileFontSize3 = "18px";
export const MobileFontSize2 = "16px";
export const MobileFontSize1 = "14px";
export const Spacing01 = "4px";
export const Spacing02 = "8px";
export const Spacing03 = "12px";
export const Spacing04 = "16px";
export const Spacing05 = "24px";
export const Spacing06 = "32px";
export const Spacing07 = "40px";
export const Spacing08 = "48px";
export const Spacing09 = "64px";
export const Spacing10 = "80px";
export const BorderRadiusLarge = "8px";
export const BorderRadiusMedium = "4px";
export const BorderRadiusSmall = "2px";
export const BorderRadiusCircle = "100%";
export const BorderFormColorDefault = "#ffffff";
export const FontWeightBlack = "#000000";
export const Spacing11 = "88px";
export const Spacing12 = "96px";
export const Spacing13 = "104px";
export const Spacing14 = "112px";
export const Spacing15 = "128px";
export const Spacing16 = "160px";

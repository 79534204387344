import React, { memo } from 'react';

const QuoteCircle = ({ color = '#6feedb', className = '', ...rest }) => (
  <svg
    className={className}
    fill="none"
    {...rest}
    width="320"
    height="320"
    viewBox="0 0 566 566"
    xmlns="http://www.w3.org/2000/svg">
    <mask width="566" height="566">
      <rect width="566" height="566" fill={color} />
    </mask>
    <g mask="url(#mask0_2961_349)">
      <circle
        cx="383.913"
        cy="48.2859"
        r="19.5"
        transform="rotate(-7.32488 383.913 48.2859)"
        fill={color}
      />
      <circle
        cx="405.405"
        cy="506.094"
        r="16"
        transform="rotate(-7.32488 405.405 506.094)"
        fill={color}
      />
      <path
        d="M103 387L159.292 419.5V484.5L103 517L46.7083 484.5V419.5L103 387Z"
        fill={color}
      />
      <path
        d="M1.17279 271.327L143.5 189.155L285.827 271.327V435.673L143.5 517.845L1.17279 435.673V271.327Z"
        stroke="#F7F7F7"
        strokeWidth="2"
      />
      <path
        d="M372.125 393.827L449.5 349.155L526.875 393.827V483.173L449.5 527.845L372.125 483.173V393.827Z"
        stroke="#F7F7F7"
        strokeWidth="2"
      />
      <circle cx="283" cy="283" r="250" fill={color} />
    </g>
  </svg>
);

export default memo(QuoteCircle);

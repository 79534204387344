import React from 'react';

const QuoteSquare = ({ color = '#6feedb', className = '', ...rest }) => {
  return (
    <svg
      className={className}
      fill="none"
      {...rest}
      width="320"
      height="320"
      viewBox="0 0 566 566"
      xmlns="http://www.w3.org/2000/svg">
      <mask width="566" height="567">
        <rect y="0.00012207" width="566" height="566" fill={color} />
      </mask>
      <g mask="url(#mask0_3012_2213)">
        <circle
          cx="383.913"
          cy="48.2859"
          r="19.5"
          transform="rotate(-7.32488 383.913 48.2859)"
          fill={color}
        />
        <circle
          cx="500.909"
          cy="409.91"
          r="16"
          transform="rotate(-7.32488 500.909 409.91)"
          fill={color}
        />
        <path
          d="M92.5 492L4.99999 405L92.5 318L180 405L92.5 492Z"
          fill={color}
        />
        <path
          d="M312.127 375.646L156.627 530.608L1.66526 375.108L157.165 220.146L312.127 375.646Z"
          stroke="#F7F7F7"
          strokeWidth="2"
        />
        <path
          d="M498.901 484.233L390.585 421.696L453.121 313.379L561.438 375.916L498.901 484.233Z"
          stroke="#F7F7F7"
          strokeWidth="2"
        />
        <circle cx="283" cy="283" r="250" fill={color} />
      </g>
    </svg>
  );
};

export default QuoteSquare;

import React from 'react';
import * as styles from './ContentfulQuote.module.scss';
import { CircleContainer, Quote } from '@vccp/bernadette-components';
import { Content } from '@vccp/bernadette-components';
import Image from '../Image/Image';
import { graphql } from 'gatsby';
import cx from 'classnames';
import useMediaQuery from '../../hooks/useMediaQuery';
import QuoteCircle from '../svgs/QuoteCircle';
import QuoteSquare from '../svgs/QuoteSquare';
import QuoteTriangle from '../svgs/QuoteTriangle';

const ContentfulQuote = ({
  narrowQuote,
  jobTitle,
  image,
  quotee,
  quote,
  reversed,
  backgroundImageOptions,
  accentcolour,
}) => {
  const isLargeScreen = useMediaQuery('(min-width: 960px)');
  const svgColor = accentcolour
    ? `#${accentcolour[0].client.clientAccent}`
    : '#6feedb';
  const getDecorationSvg = () => {
    switch (backgroundImageOptions) {
      case 'Circle':
        return (
          <QuoteCircle
            color={svgColor}
            className={styles.quoteImageDecoration}
          />
        );
      case 'Square':
        return (
          <QuoteSquare
            color={svgColor}
            className={styles.quoteImageDecoration}
          />
        );
      case 'Triangle':
        return (
          <QuoteTriangle
            color={svgColor}
            className={styles.quoteImageDecoration}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Content>
      <div className={styles.quoteWrapperOuter}>
        <div
          className={cx(styles.quoteWrapper, {
            [styles.rowReverse]: reversed && isLargeScreen,
            [styles.colReverse]: reversed && !isLargeScreen,
          })}>
          <div className={styles.quoteTextContent}>
            <Quote
              highlightColor={
                accentcolour
                  ? `#${accentcolour[0].client.clientAccent}`
                  : '#6feedb'
              }>
              <div
                className={cx(styles.quoteText, {
                  [styles.quoteNoImage]: !narrowQuote || !image,
                  [styles.quoteWithImage]: image,
                })}>
                <span className={styles.quoteTitle}>{quote.quote}</span>
                <p
                  className={cx({
                    [styles.quoteNoImageDesc]: !narrowQuote || !image,
                  })}>
                  {quotee}
                  <br />
                  {jobTitle ? `${jobTitle}` : ''}
                </p>
              </div>
            </Quote>
          </div>
          {!narrowQuote && image && (
            <div style={{ position: 'relative' }}>
              <CircleContainer>
                {image && <Image {...image} className={styles.quoteImage} />}
              </CircleContainer>
              {backgroundImageOptions && getDecorationSvg()}
            </div>
          )}
        </div>
      </div>
    </Content>
  );
};

export default ContentfulQuote;

export const ContentfulComponentQuoteFields = graphql`
  fragment ContentfulComponentQuoteFields on ContentfulComponentQuote {
    jobTitle
    image {
      gatsbyImageData
      title
    }
    reversed
    quotee
    backgroundImageOptions
    narrowQuote
    quote {
      quote
    }
    accentcolour: project {
      client {
        clientAccent
      }
    }
  }
`;

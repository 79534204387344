import React from 'react';

const QuoteTriangle = ({ color = '#6feedb', className = '', ...rest }) => {
  return (
    <svg
      className={className}
      fill="none"
      {...rest}
      width="320"
      height="320"
      viewBox="0 0 566 566"
      xmlns="http://www.w3.org/2000/svg">
      <mask width="566" height="567">
        <rect y="0.00012207" width="566" height="566" fill={color} />
      </mask>
      <g mask="url(#mask0_3012_1150)">
        <circle
          cx="117.827"
          cy="85.8272"
          r="19.5"
          transform="rotate(-7.32488 117.827 85.8272)"
          fill={color}
        />
        <circle
          cx="448.909"
          cy="473.91"
          r="16"
          transform="rotate(-7.32488 448.909 473.91)"
          fill={color}
        />
        <path
          d="M25.8321 529.04L72.0057 356.718L198.154 482.867L25.8321 529.04Z"
          fill={color}
        />
        <path
          d="M237.011 454.972L6.17985 516.395L68.4017 285.778L237.011 454.972Z"
          stroke="#F7F7F7"
          strokeWidth="2"
        />
        <path
          d="M354.016 85.5946L502.079 45.9212L462.405 193.985L354.016 85.5946Z"
          stroke="#F7F7F7"
          strokeWidth="2"
        />
        <circle cx="283" cy="283" r="250" fill={color} />
      </g>
    </svg>
  );
};

export default QuoteTriangle;
